import React, { Component } from "react";
import GoogleMap from "google-map-react";
import { GOOGLE_MAP_API_KEY } from "../constants/google-key";
import logoIcono from "../img/icono-wimmic.svg";

const K_WIDTH = 40;
const K_HEIGHT = 40;
const STYLE_GREAT_PLACE = {
  // initially any map object has left top corner at lat lng coordinates
  // it's on you to set object origin to 0,0 coordinates
  position: "absolute",
  width: K_WIDTH,
  height: K_HEIGHT,
  left: -K_WIDTH / 2,
  top: -K_HEIGHT / 2,

  borderRadius: K_HEIGHT,
  backgroundColor: "white",
  textAlign: "center",
  color: "#3f51b5",
  fontSize: 16,
  fontWeight: "bold",
  padding: 4,
  borderColor: "#EFF0F2",
  borderSize: 3,

  display: "flex",
  alignItems: "center",
  justifyContent: "center"
};

class MyGreatPlace extends Component {
  render() {
    return (
      <div style={STYLE_GREAT_PLACE}>
        <img src={logoIcono} alt="Wimmic Logo" style={{ width: "36px" }} />
      </div>
    );
  }
}

const MAP_PROPS = {
  zoom: 5
};

export default class Map extends Component {
  render() {
    const { lat, lng } = this.props.coordinates;
    const { zoom = MAP_PROPS.zoom } = this.props;
    return (
      <GoogleMap apiKey={GOOGLE_MAP_API_KEY} center={[lat, lng]} zoom={zoom}>
        {zoom > 5 && <MyGreatPlace lat={lat} lng={lng} />}
      </GoogleMap>
    );
  }
}
