import React from "react";
import { graphql } from "gatsby";

import { navigate } from "gatsby-link";
import Layout from "../../components/Layout";
import ImageHero from "../../components/ImageHero";
import Map from "../../components/Map";
import { addresses } from "../../constants/addresses";

function encode(data) {
  return Object.keys(data)
    .map((key) => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
    .join("&");
}

const baumail = (data, emailKey = "wimmic") => {
  return fetch(
    "https://91w4rcp6l3.execute-api.eu-west-1.amazonaws.com/prod/send",
    {
      credentials: "omit",
      headers: {
        accept: "text/plain, */*; q=0.01",
        "content-type": "text/plain",
        "x-api-key": "g8PNQRDU8naA3aNdqP9gE5hHiZyhsGwq2ZUnEu1R",
      },
      referrer: "http://customyoga.es/contacto.html",
      referrerPolicy: "no-referrer-when-downgrade",
      body: JSON.stringify({
        emailKey,
        data,
      }),
      method: "POST",
      mode: "cors",
    }
  );
};
const baumail2 = (data, emailkey = "wimmic") => {
  const aws =
      "OTF3NHJjcDZsMy5leGVjdXRlLWFwaS5ldS13ZXN0LTEuYW1hem9uYXdzLmNvbQ==",
    url = "https://" + atob(aws) + "/prod/" + "send";
  const headers = {
    "Content-Type": "application/x-www-form-urlencoded",
    "x-api-key": "g8PNQRDU8naA3aNdq" + "P9gE5hHiZyhsGwq2ZUnEu1R",
  };
  return fetch(url, {
    method: "POST",
    headers,
    body: encode({
      emailkey,
      data: JSON.stringify(data),
    }),
  });
};

class ContactForm extends React.Component {
  render() {
    const { handleSubmit, handleChange, style } = this.props;
    return (
      <form
        name="contact"
        method="post"
        action="/contact/thanks/"
        data-netlify="true"
        data-netlify-honeypot="bot-field"
        onSubmit={handleSubmit}
        style={style}
      >
        {/* The `form-name` hidden field is required to support form submissions without JavaScript */}
        <input type="hidden" name="form-name" value="contact" />
        <div hidden>
          <label>
            Don’t fill this out:
            <input name="bot-field" onChange={handleChange} />
            <input
              className="input"
              type="hidden"
              name={"subject"}
              value="[WIMMIC]: Contacto WEB"
            />
          </label>
        </div>
        <div className="field">
          <label className="label" htmlFor={"name"}>
            Your name
          </label>
          <div className="control">
            <input
              className="input"
              type={"text"}
              name={"name"}
              onChange={handleChange}
              id={"name"}
              required={true}
            />
          </div>
        </div>
        <div className="field">
          <label className="label" htmlFor={"email"}>
            Email
          </label>
          <div className="control">
            <input
              className="input"
              type={"email"}
              name={"email"}
              onChange={handleChange}
              id={"email"}
              required={true}
            />
          </div>
        </div>
        <div className="field">
          <label className="label" htmlFor={"message"}>
            Message
          </label>
          <div className="control">
            <textarea
              className="textarea"
              name={"message"}
              onChange={handleChange}
              id={"message"}
              required={true}
            />
          </div>
        </div>
        <div className="field">
          <input type="checkbox" name="accept" id="privacy" required />
          <span className="accept-label">
            I accept the{" "}
            <a href="/privacy-policy" target="_blank" rel="noopener noreferrer">
              privacy policy
            </a>
          </span>
        </div>
        <div className="field">
          <button className="button is-primary disabled" type="submit">
            Send message
          </button>
        </div>
      </form>
    );
  }
}

export default class Index extends React.Component {
  constructor(props) {
    super(props);
    this.state = { isValidated: false, zoom0: 11, zoom1: 11, zoom2: 11 };
  }

  updateZoom = (i, zoom = 11) => () => {
    const { zooms } = this.state;
    this.setState({ ["zoom" + i]: zoom });
  };
  componentDidMount() {
    const baseTimeout = 1 * 1000;
    const diffTimeout = 600;
    setTimeout(this.updateZoom(0, 5), 100);
    setTimeout(this.updateZoom(1, 5), 100);
    setTimeout(this.updateZoom(2, 5), 100);
    setTimeout(this.updateZoom(0), baseTimeout + diffTimeout);
    setTimeout(this.updateZoom(1), baseTimeout + diffTimeout * 2);
    setTimeout(this.updateZoom(2), baseTimeout + diffTimeout * 3);
  }

  handleChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  handleSubmit = (e) => {
    e.preventDefault();
    const form = e.target;

    baumail({
      email: this.state.email,
      name: this.state.name,
      message: this.state.message,
      pagina: "https://wimmic.com/contact/",
    })
      .then(() => navigate(form.getAttribute("action")))
      .catch((error) => alert(error));

    // Netlify form
    fetch("/", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: encode({
        "form-name": form.getAttribute("name"),
        ...this.state,
      }),
    });
  };

  render() {
    const {
      data: {
        heroImage: { childImageSharp },
      },
    } = this.props;
    return (
      <Layout>
        <ImageHero
          heading={"Contact"}
          childImageSharp={childImageSharp}
          style={{ backgroundPosition: "center center" }}
          stylesTransparency={{ backgroundColor: "rgba(0,0,0,0.1)" }}
        />
        <section className="section">
          <div className="container">
            <div className="section">
              <div className="columns">
                <div className="column">
                  <div className="content">
                    <div
                      className="columns"
                      style={{ justifyContent: "center" }}
                    >
                      {addresses.map((a, i) => (
                        <div
                          className="column"
                          style={{
                            display: "flex",
                            maxWidth: "390px",
                            margin: "0 auto",
                          }}
                          key={i}
                        >
                          <div
                            className="box contact"
                            style={{ width: "100%" }}
                          >
                            <article
                              className="media map"
                              style={{ height: "230px" }}
                            >
                              <Map
                                coordinates={a.coordinates}
                                zoom={this.state["zoom" + i]}
                              />
                            </article>
                            <article className="media address">
                              <div className="media-content">
                                <div className="content">
                                  <div>
                                    <h3
                                      style={{
                                        fontSize: 16,
                                        maginBottom: 10,
                                      }}
                                    >
                                      {a.name}
                                    </h3>
                                    {a.address.map((a, i) => (
                                      <div key={i}>{a}</div>
                                    ))}
                                  </div>
                                </div>
                              </div>
                            </article>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* CONTACT FORM */}
        <section className="contact-form-section">
          <div className="container">
            <div className="section">
              <div className="columns">
                <div className="column is-10 is-offset-1">
                  <div className="content">
                    <div className="columns">
                      <div className="column contact-form-wrapper">
                        <h3 className="title">Contact us</h3>
                        <ContactForm
                          handleSubmit={this.handleSubmit}
                          handleChange={this.handleChange}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </Layout>
    );
  }
}

export const contactPageQuery = graphql`
  query ContactPageTemplate {
    heroImage: file(relativePath: { eq: "wimmic-satellite.jpg" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`;
