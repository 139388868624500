export const addresses = [
  {
    name: "Headquarters",
    address: [
      "Avenida Alcalde Ramírez Bethencourt, 17",
      "35004 - Las Palmas de Gran Canaria",
      "Spain"
    ],
    country: "Spain",
    coordinates: { lat: 28.117089, lng: -15.420186 }
  },
  {
    name: "Lab",
    address: [
      "Institute for Applied Microelectronics (IUMA)",
      "University of Las Palmas de Gran Canaria",
      "35017 - Las Palmas de Gran Canaria",
      "Spain"
    ],
    country: "Spain",
    coordinates: { lat: 28.080586, lng: -15.452518 }
  },
  {
    name: "UK Branch",
    address: [
      "Quad 1 Suite 4A, Ground Floor",
      "Becquerel Avenue, Harwell Campus",
      "OX11 0RA - Harwell, Didcot Oxfordshire",
      "United Kingdom"
    ],
    country: "UK",
    coordinates: { lat: 51.581031, lng: -1.307774 }
  }
];
